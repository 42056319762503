//import './js/accessibility';
import './js/forms';

import './templates/site-nav/index';
import './templates/site-search/toggle';
import './templates/site-head/index';
import './templates/sections/contact_information/index';
import './templates/cookie-banner/index';
import './templates/filter/index';

import 'owl.carousel';
import 'lity';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/blur-up/ls.blur-up';

$(".related-products-carousel").each(function(){
    $(this).owlCarousel({
        loop:false,
        nav:true,
        dots: false,
        margin: 5,
        mouseDrag:  false,
        navText: ['',''],
        items: 1,
        responsive:{
            0:{
                items: 1
            },
            720:{
                items: 3
            },
            980:{
                items: 4
            }
        },
        onInitialized: (function(e) {
            this.$element.addClass('owl-carousel');
            this.$element.find('.cloned, .owl-nav').attr({
                'aria-hidden': true,
                'role'       : 'presentation',
            });
          })
    });
  });

  $(".related-recipes-carousel").each(function(){
    $(this).owlCarousel({
        loop: false,
        nav: true,
        dots: false,
        margin: 5,
        mouseDrag:  false,
        navText: ['',''],
        items: 1,
        responsive:{
            0:{
                items: 1
            },
            720:{
                items: 3
            },
            980:{
                items: 3
            }
        },
        onInitialized: (function(e) {
            this.$element.addClass('owl-carousel');
            this.$element.find('.cloned, .owl-nav').attr({
                'aria-hidden': true,
                'role'       : 'presentation',
            });
          })
    });
  });

  $(".image-gallery").each(function(){
    $(this).owlCarousel({
        items: 1,
        loop:false,
        nav:false,
        dots: true,
        margin: 0,
        mouseDrag:  false,
        dotsContainer: '.image-gallery__dots',
        navText: ['',''],
        onInitialized: (function(e) {
            this.$element.addClass('owl-carousel');
            this.$element.find('.cloned, .owl-nav').attr({
                'aria-hidden': true,
                'role'       : 'presentation',
            });
            $('.image-gallery__dots li').click(function () {
                $(this).parent().prev().owlCarousel().trigger('to.owl.carousel', [$(this).index(), 300]);
            });
          })
    });
  });

function printRecipe(){
    $('.button--print').click(function(e){
        e.preventDefault;
        window.print();
    });
}
printRecipe();

const calculateFeaturedPost = () => {
    let postItem = $('.post-item-alt')
    let height = postItem.outerHeight()

    $('.page-head .page-head__content').css({
        'padding-bottom': height/2
    })

    $('.page-head').css({
        'margin-bottom': -height/2
    })
}

$(window).on("load resize", function () {
    if ($(".post-item-alt")[0]){
        calculateFeaturedPost()
    }
})




