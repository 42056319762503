import $ from 'jquery';
import store from '../../js/store';


store.$app.on('submit', '.filter form', (function(e) {
    const $this = $(this),
    url   = $this.find('select').val();

    e.preventDefault();

    window.location.href = url;
    $this.prop("disabled", true);
  
  }));