import $ from 'jquery';
import store from '../../js/store';

import {debounce} from 'throttle-debounce';

const storage = window.sessionStorage,
      KEY = 'macphie-head-height';

const $head     = $('#site-head');

const $behindHeader = $('[data-behind-header]'),
      $clearHeader  = $('[data-clear-header]'),
      $belowHeader  = $('[data-below-header]');


let headHeight     = storage.getItem(KEY) ? parseInt(storage.getItem(KEY)) : null;

const clearHeader = (function(height = null, animate = true) {

    if(!height) {
        headHeight = $head.outerHeight();
    } else {
        headHeight = height;
    }

    storage.setItem(KEY, headHeight);

    if(!animate) {
        $clearHeader.css('transition', 'none');
        $behindHeader.css('transition','none');
        $belowHeader.css('transition','none');
    }

    $clearHeader.css('padding-top', `${headHeight}px`);
    $behindHeader.css('margin-top', `-${headHeight}px`);
    $belowHeader.css('top', `${headHeight}px`);

    setTimeout(() => {
        $clearHeader.css('transition','');
        $behindHeader.css('transition','');
        $belowHeader.css('transition','');
    }, 10);

});


clearHeader(headHeight, false);
store.$window.on('resize', debounce(100, () => clearHeader()));

export { clearHeader, clearHeader as default };