import $ from 'jquery';
import store from '../../js/store';
import {disableScroll, enableScroll} from '../../js/disable-scroll';

import toggle_menu from '../site-nav/toggle';

const active_class = 'app--search-active',
      selector = '.site-search-toggle',
      $toggles = $(selector),
      $search = $('#site-search'),
      $mains = $('#site-main, #site-foot');

store.$app.on('click', selector, (e) => {
    e.preventDefault();

    var open = !store.$app.hasClass(active_class);
    toggle_menu(false);
    toggle_search(open);
});

const toggle_search = (function(open = true) {

    if(open) {
        $toggles.attr({
            'aria-pressed': 'true',
            'aria-label': 'Close Search',
        });

        store.$app.addClass(active_class);

        setTimeout(() => {
            $('input[name="s"]', $search).focus();
            disableScroll();
        }, 250);

        $mains.attr('aria-hidden', 'true');
    } else {

        $toggles.attr({
            'aria-pressed': 'false',
            'aria-label': 'Show Search'
        });

        store.$app.removeClass(active_class);

        enableScroll(false);

        $mains.attr('aria-hidden', 'false');

    }

    store.navActive = open;

});


export { active_class, $toggles, toggle_search, toggle_search as default };