import $ from 'jquery';
//import constants from '../../../../../constants.json';

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(searchString, length) {
    if (!(length < this.length)) {
      length = this.length;
    } else {
      length |= 0; // round position
    }

    return this.substr(length - searchString.length, searchString.length) === searchString;
  };
}

let constants = {};

export default {
  resolution: window.devicePixelRatio || 1,
  $document: $(document),
  $window: $(window),
  $app: $('body'),
  body: document.getElementsByTagName('body')[0],
  webFontLoaded: [],
  constants,
  google: {
    key: 'AIzaSyAN2lOTGtpcmtOFDdOa2vXEA88LM2n7kks'
  },
  env: (window.location.hostname.endsWith('.dev') || window.location.hostname === 'localhost' ? 'development' : (window.location.hostname.endsWith('.madebrave.com') ? 'staging' : 'production'))
}
