import $ from 'jquery';

var KEY_MACPHIE = 'macphie-cookies'
var VALUE_MACPHIE= 'verified'

function createCookie(name,value,days) {
    var expires = "";

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

$(document).ready(function() {
if(readCookie(KEY_MACPHIE) !== VALUE_MACPHIE) {
  $('#cookie-bar').show();
  $('body').on('click','#cookie-bar__continue', function(e) {
      createCookie(KEY_MACPHIE, VALUE_MACPHIE, 999);

      $('#cookie-bar').fadeOut(600);
  });

}
});