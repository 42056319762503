import $ from 'jquery';
import store from '../../js/store';
import {disableScroll, enableScroll} from '../../js/disable-scroll';

import $nav from './index';
import toggle_search from '../site-search/toggle';

const active_class = 'app--nav-active',
      selector = '.site-nav-toggle',
      $toggles = $(selector),
      $mains = $('#site-main, #site-foot');

store.navActive = false;

store.$app.on('click', selector, (e) => {
    e.preventDefault();

    var open = !store.$app.hasClass(active_class);
    toggle_search(false);
    toggle_menu(open);
});

const toggle_menu = (function(open = true) {

    if(open) {
        $toggles.attr({
            'aria-pressed': 'true',
            'aria-label': 'Close Main Menu',
        });

        store.$app.addClass(active_class);

        setTimeout(() => {
            $nav.focus();
            disableScroll();
        }, 250);

        $nav.attr('aria-hidden', 'false');
        $mains.attr('aria-hidden', 'true');
    } else {

        $toggles.attr({
            'aria-pressed': 'false',
            'aria-label': 'Show Main Menu'
        });

        store.$app.removeClass(active_class);

        enableScroll(false);

        $nav.attr('aria-hidden', 'true');
        $mains.attr('aria-hidden', 'false');

    }

    store.navActive = open;

});


export { active_class, $toggles, toggle_menu, toggle_menu as default };