import $ from 'jquery';
import store from './store';

const insetLabel = (function(e) {

  const $elem = $(e.target),
        value = $elem.val();

  if(value || e.type === 'focus' || e.type === 'focusin') {
    $elem.closest('.inset-label').addClass('inset-label--active');
  } else {
    $elem.closest('.inset-label').removeClass('inset-label--active');
  }

});

const createGravityInsetLabels = (function() {

  $(`.ginput_container_text,
     .ginput_container_phone,
     .ginput_container_email,
     .ginput_container_website,
     .ginput_container_address,
     .ginput_container_textarea,
     .ginput_container_select`).find('input, select, textarea').each(function() {
    const $this = $(this),
          $container = $this.closest('.gfield'),
          $label = $('.gfield_label', $container);

    if($this.hasClass('inset-label__field')) {
      return true;
    }

    $container.addClass('inset-label');
    $this.addClass('inset-label__field');
    $label.addClass('inset-label__label');

  });
});


store.$app.on('change keyup focus blur', '.inset-label__field', insetLabel);
$('.inset-label__field').change();

$(document).on('gform_post_render', function() {
  createGravityInsetLabels();
  $('.inset-label__field').change();
});